export default ({ effects }) => async order => {

    const expectedTerms = 'payLater';

    if (order.completed) {
        throw new Error('Order is already completed');
    }

    if (order.terms !== expectedTerms) {
        throw new Error(`Expected terms to be "${expectedTerms}" but was "${order.terms}"`);
    }

    const errorMessage = 'We\'re sorry, something went wrong while processing your order';

    const captureOrder = async () => {
        const request = () => effects.captureOrder({ ...order, paymentMethod: 'instore' });
        const { orderId } = await effects.makeRequest(request, errorMessage);
        return { orderId };
    };

    const capturePayment = ({ orderId }) => {
        const request = () => effects.capturePayment({ orderId });
        return effects.makeRequest(request, errorMessage);
    };

    const { orderId } = await captureOrder();
    await capturePayment({ orderId });

};
