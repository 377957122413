export default ({ ui }) => ({ quantity, onQuantityChange, onAdd }) => {

    const changeQuantity = e => {
        const diff = parseInt(e.target.value + '1');
        const q = quantity + diff;
        onQuantityChange(q === 0 ? 1 : q);
    };

    return <div className={ui.cssClasses('quantity-bar', 'horizontal-spacing')}>
        <span>
            <button title="Decrease" value="-" onClick={changeQuantity}>–</button>
            <span className={ui.cssClass('quantity')}>{quantity}</span>
            <button title="Increase" value="+" onClick={changeQuantity}>+</button>
        </span>
        <button onClick={onAdd}>Add to order</button>
    </div>;

};
