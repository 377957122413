export default ({ reactRouter, pureComponents, effects, ui, constants }) => ({ order }) => {

    const navigate = reactRouter.useNavigate();

    const sections = constants.menu.sections.map((section, s) => {
        const products = section.products.map(product => {
            return <pureComponents.Product key={product.name}
                product={product}
                selected={effects.isProductSelected(product)}
                onSelect={effects.toggleProduct}
                onAdd={effects.addItem}
            />;
        });
        return <div key={s}>
            <h2>{section.sectionName}</h2>
            {products}
        </div>;
    });

    const onCheckout = () => navigate('/checkout');

    return <div>
        <pureComponents.Notices />
        <pureComponents.CheckoutBar order={order} onCheckout={onCheckout} />
        <div className={ui.cssClass('menu')}>{sections}</div>
    </div>;

};
