import components from './components/index.mjs';
import effects from './effects/index.mjs';
import io from './io/index.mjs';
import lib from './lib/index.mjs';
import pureComponents from './pure-components/index.mjs';
import ui from './ui/index.mjs';
import views from './views/index.mjs';

export default {
    components,
    effects,
    io,
    lib,
    pureComponents,
    ui,
    views
};
