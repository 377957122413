/* eslint-disable indent */
export default ({ reactRouter, effects }) => () => {

    const { Navigate } = reactRouter;
    const location = reactRouter.useLocation();
    const order = effects.getOrder();

    switch (location.pathname) {
        case '/':
            if (order.completed) return <Navigate to="/completed" />;
            break;
        case '/checkout':
            if (order.completed) return <Navigate to="/completed" />;
            if (order.terms) return <Navigate to="/payment" />;
            if (order.empty) return <Navigate to="/" />;
            break;
        case '/payment':
            if (order.completed) return <Navigate to="/completed" />;
            if (!order.terms) return <Navigate to="/checkout" />;
            if (order.empty) return <Navigate to="/" />;
            break;
        case '/completed':
            if (!order.completed) return <Navigate to="/payment" />;
            break;
        default:
            return null;
    }

};

