export default ({ react, ui, constants }) => ({ order, onSubmit, calculateMinBookingDate }) => {

    const [terms, setTerms] = react.useState('');

    const handleSubmit = e => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const booking = Object.fromEntries(formData.entries());
        onSubmit(booking, terms);
    };

    const handleClick = e => {
        setTerms(e.target.value);
        const minDate = calculateMinBookingDate();
        const min = new Intl.DateTimeFormat('sv-SE', { timeZone: constants.menu.timeZone, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).format(minDate).replace(' ', 'T');
        e.target.form.elements.datetime.min = min;
    };

    const input = (name, type, label, opts = {}) => {
        const required = opts.required ?? true;
        return <div>
            <label htmlFor={name}>{label}</label>
            <input type={type} id={name} name={name} defaultValue={order.booking[name]} required={required} />
            {opts.example && <div className={ui.cssClass('example')}>{opts.example}</div>}
        </div>;
    };

    const paypalEnabled = constants.menu.paypalEnabled ?? true;
    const payNow = paypalEnabled ? <button type="submit" value="payNow" onClick={handleClick}>Continue to payment</button> : null;
    const payLater = <button type="submit" value="payLater" onClick={handleClick}>Submit and settle instore</button>;

    return <div>
        <form className={ui.cssClass('booking')} onSubmit={handleSubmit}>
            {input('name', 'text', 'Name')}
            {input('phone', 'tel', 'Phone')}
            {input('email', 'email', 'Email')}
            {input('datetime', 'datetime-local', 'Date and time', { example: `${constants.menu.hoursNotice} hours notice required` })}
            {input('location', 'text', 'Location', { example: `e.g. ${constants.menu.locationExample}` })}
            {input('comment', 'text', 'Comment', { required: false })}
            {payNow}
            {payLater}
        </form>
    </div >;

};
