export default ({ react, pureComponents, lib, ui }) => ({ product, selected, onSelect, onAdd }) => {

    const [optionIndex, setOptionIndex] = react.useState(0);
    const [fillingIndex, setFillingIndex] = react.useState(0);
    const [quantity, setQuantity] = react.useState(1);
    const [lastItemAdded, setLastItemAdded] = react.useState(null);

    const contentRef = react.createRef();
    const customisationsRef = react.createRef();

    react.useEffect(() => {
        const animate = selected ? ui.expand : ui.collapse;
        animate(contentRef.current);
    }, [selected]);

    react.useEffect(() => {
        const animate = lastItemAdded ? ui.collapse : ui.expand;
        animate(customisationsRef.current);
    }, [lastItemAdded]);

    const handleAdd = () => {
        const option = product.options[optionIndex]?.name;
        const filling = product.fillings[fillingIndex]?.name;
        const item = { name: product.name, option, filling, quantity };
        setLastItemAdded(lib.describeItem(item));
        onAdd(item);
    };

    const handleAddAnother = () => {
        setOptionIndex(0);
        setFillingIndex(0);
        setQuantity(1);
        setLastItemAdded(null);
    };

    return <div className={ui.cssClasses('product', ['selected', selected])}>
        <pureComponents.ProductHeader product={product} onSelect={onSelect} />
        <div ref={contentRef} className={ui.cssClasses('product-content', 'collapsable')}>
            <div ref={customisationsRef} className={ui.cssClasses('customisations', 'collapsable')}>
                <pureComponents.Options product={product} optionIndex={optionIndex} onOptionChange={setOptionIndex} />
                <pureComponents.Fillings product={product} fillingIndex={fillingIndex} onFillingChange={setFillingIndex} />
            </div>
            <div className={ui.cssClass('visible', !lastItemAdded)}>
                <pureComponents.Quantity quantity={quantity} onQuantityChange={setQuantity} onAdd={handleAdd} />
            </div>
            <div className={ui.cssClass('visible', !!lastItemAdded)}>
                <pureComponents.ItemAdded lastItemAdded={lastItemAdded} onAddAnother={handleAddAnother} />
            </div>
        </div>
    </div >;

};
