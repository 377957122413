export default ({ ui, constants }) => ({ hoursNoticeVisible = true, minOrderVisible = true }) => {

    const hoursNotice = `${constants.menu.hoursNotice} hours notice required for all catering orders.`;
    const minOrder = `Minimum order is $${constants.menu.minOrder}.`;

    const notices = [];
    if (constants.menu.hoursNotice && hoursNoticeVisible) notices.push(hoursNotice);
    if (constants.menu.minOrder && minOrderVisible) notices.push(minOrder);

    if (!notices.length) return null;

    const noticeElements = notices.map((notice, i) => {
        return <span key={i}>{notice}</span>;
    });

    return <p className={ui.cssClass('notices')}>{noticeElements}</p>;

};
