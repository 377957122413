const maxPriceDifference = 5;

export default ({ lib }) => (option, product) => {

    const productMinPrice = lib.sortPrices(product)[0];
    const priceDifference = option.price - productMinPrice;
    const priceDifferenceApplicable = priceDifference > 0 && priceDifference <= maxPriceDifference;
    const defaultDisplayPrice = product.price && option.price === product.price ? '' : option.price;
    return priceDifferenceApplicable ? `+${priceDifference.toFixed(2)}` : defaultDisplayPrice;

};
