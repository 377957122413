export default {
    stage: 'prod',
    appName: 'Caffe Stazione Catering',
    bffUrl: 'https://vvprzr53dy7upjvq44sgvk4pnu0jxrmm.lambda-url.ap-southeast-2.on.aws',
    client: 'caffe-stazione',
    initialState: {
        order: {
            items: {},
            booking: {}
        },
        selectedProduct: null,
        error: null
    },
    isTest: false,
    isSynthetic: false,
    paypal: {
        client: 'Acp5YkgKW_-eK4Nw7A_yCGbk8vzHmqFvYPka1-87fgvZyL6r3YS35ismsmcAkyHKHi9g0bdLwNMHl51a'
    },
    mixpanelToken: '0ec6828d49e0c21e42a4b08a6430a96e',
    menu: {
        id: 'catering-menu',
        clientId: 'caffe-stazione',
        storeName: 'Caffe Stazione',
        transactionDescription: 'Catering services',
        currency: 'AUD',
        minOrder: 50,
        hoursNotice: 48,
        timeZone: 'Australia/Melbourne',
        locationExample: 'Realm meeting room 3',
        paypalEnabled: true,
        syntheticState: {
            order: {
                items: {},
                booking: {
                    name: 'John Doe',
                    phone: '0365554036',
                    email: 'sb-peeh526984152@business.example.com',
                    datetime: '2030-01-01T12:00',
                    location: 'TEST ONLY',
                    comment: 'TEST ONLY'
                }
            }
        },
        sections: [
            {
                sectionName: 'Savoury',
                fillings: [
                    'Ham, cheese & tomato',
                    'Chicken, mayo, & lettuce',
                    'Egg, cheese & mayo',
                    'Salami, cheese & rocket',
                    'Turkey, cranberry, swiss & cos',
                    'Olive pateortolana - vegan cheese, eggplant, spinach, basil, capsicum'
                ],
                products: [
                    {
                        name: 'Mini Egg & Bacon Brioche Rolls',
                        price: 5.3,
                        per: 'each',
                        details: '',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'Mini Bagels',
                        price: 5.5,
                        per: 'each',
                        options: [
                            {
                                name: 'Smoked salmon & cream cheese',
                                price: 5.5
                            },
                            {
                                name: 'Smoked turkey & cranberry sauce',
                                price: 5.5
                            }
                        ],
                        details: '',
                        fillings: []
                    },
                    {
                        name: 'Mini Croissants',
                        price: 5.5,
                        per: 'each',
                        details: '',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'Baguettes',
                        details: 'cut in quarters',
                        price: 12.4,
                        per: 'each',
                        hasFillings: true,
                        options: [],
                        fillings: [
                            {
                                name: 'Ham, cheese & tomato'
                            },
                            {
                                name: 'Chicken, mayo, & lettuce'
                            },
                            {
                                name: 'Egg, cheese & mayo'
                            },
                            {
                                name: 'Salami, cheese & rocket'
                            },
                            {
                                name: 'Turkey, cranberry, swiss & cos'
                            },
                            {
                                name: 'Olive pateortolana - vegan cheese, eggplant, spinach, basil, capsicum'
                            }
                        ]
                    },
                    {
                        name: 'Wraps',
                        details: 'cut in thirds',
                        price: 12.4,
                        per: 'each',
                        hasFillings: true,
                        options: [
                            {
                                name: 'Standard',
                                price: 12.4
                            },
                            {
                                name: 'Gluten free',
                                price: 12.4
                            }
                        ],
                        fillings: [
                            {
                                name: 'Ham, cheese & tomato'
                            },
                            {
                                name: 'Chicken, mayo, & lettuce'
                            },
                            {
                                name: 'Egg, cheese & mayo'
                            },
                            {
                                name: 'Salami, cheese & rocket'
                            },
                            {
                                name: 'Turkey, cranberry, swiss & cos'
                            },
                            {
                                name: 'Olive pateortolana - vegan cheese, eggplant, spinach, basil, capsicum'
                            }
                        ]
                    },
                    {
                        name: 'Sandwiches',
                        details: 'cut in quarters',
                        price: 12.9,
                        per: 'each',
                        hasFillings: true,
                        options: [
                            {
                                name: 'White',
                                price: 12.9
                            },
                            {
                                name: 'Wholemeal',
                                price: 12.9
                            },
                            {
                                name: 'Multigrain',
                                price: 12.9
                            },
                            {
                                name: 'Gluten free',
                                price: 13.4
                            }
                        ],
                        fillings: [
                            {
                                name: 'Ham, cheese & tomato'
                            },
                            {
                                name: 'Chicken, mayo, & lettuce'
                            },
                            {
                                name: 'Egg, cheese & mayo'
                            },
                            {
                                name: 'Salami, cheese & rocket'
                            },
                            {
                                name: 'Turkey, cranberry, swiss & cos'
                            },
                            {
                                name: 'Olive pateortolana - vegan cheese, eggplant, spinach, basil, capsicum'
                            }
                        ]
                    },
                    {
                        name: 'Mini Rolls',
                        price: 5.5,
                        per: 'each',
                        hasFillings: true,
                        options: [
                            {
                                name: 'White',
                                price: 5.5
                            },
                            {
                                name: 'Wholemeal',
                                price: 5.5
                            },
                            {
                                name: 'Multigrain',
                                price: 5.5
                            }
                        ],
                        details: '',
                        fillings: [
                            {
                                name: 'Ham, cheese & tomato'
                            },
                            {
                                name: 'Chicken, mayo, & lettuce'
                            },
                            {
                                name: 'Egg, cheese & mayo'
                            },
                            {
                                name: 'Salami, cheese & rocket'
                            },
                            {
                                name: 'Turkey, cranberry, swiss & cos'
                            },
                            {
                                name: 'Olive pateortolana - vegan cheese, eggplant, spinach, basil, capsicum'
                            }
                        ]
                    },
                    {
                        name: 'Mini Beef or Chicken Dim Sims',
                        price: 12,
                        per: 'serve of 10',
                        details: 'serve of 10',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'Cocktail Vegetable Spring Rolls',
                        price: 12,
                        per: 'serve of 10',
                        details: 'serve of 10',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'Mini Chicken Kiev Balls',
                        price: 15,
                        per: 'serve of 10',
                        details: 'serve of 10',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'Boneless Crumbed Chicken Wings',
                        price: 12,
                        per: 'serve of 6',
                        details: 'serve of 6',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'Spiced Rice Bites',
                        price: 12,
                        per: 'serve of 10',
                        options: [
                            {
                                name: 'Thai',
                                price: 12
                            },
                            {
                                name: 'Indian',
                                price: 12
                            }
                        ],
                        details: 'serve of 10',
                        fillings: []
                    },
                    {
                        name: 'Beef Mini Pastry',
                        price: 15,
                        per: 'serve of 6',
                        details: 'serve of 6',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'Spinach & Feta Mini Pastry',
                        price: 15,
                        per: 'serve of 6',
                        details: 'serve of 6',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'Mixed Arancini Balls',
                        price: 15,
                        per: 'serve of 10',
                        options: [
                            {
                                name: 'Tomato & basil',
                                price: 15
                            },
                            {
                                name: 'Pumpkin & feta',
                                price: 15
                            },
                            {
                                name: 'Mushroom & white wine',
                                price: 15
                            },
                            {
                                name: 'Bacon & cheese',
                                price: 15
                            }
                        ],
                        details: 'serve of 10',
                        fillings: []
                    }
                ]
            },
            {
                sectionName: 'Gluten Free Options',
                products: [
                    {
                        name: 'GF Spinach & Ricotta Rolls',
                        price: 5.5,
                        per: 'each',
                        details: '',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'GF Mini Sausage Rolls',
                        price: 5.5,
                        per: 'each',
                        details: '',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'GF Pumpkin Arancini',
                        price: 5.5,
                        per: 'each',
                        details: '',
                        options: [],
                        fillings: []
                    }
                ]
            },
            {
                sectionName: 'Pizza',
                products: [
                    {
                        name: 'Pizza Tray',
                        price: 50,
                        per: 'serve of 24 pieces',
                        options: [
                            {
                                name: 'Margarita',
                                price: 50
                            },
                            {
                                name: 'Salami',
                                price: 50
                            },
                            {
                                name: 'Mushroom',
                                price: 50
                            },
                            {
                                name: 'Eggplant & pumpkin',
                                price: 50
                            }
                        ],
                        details: 'serve of 24 pieces',
                        fillings: []
                    },
                    {
                        name: 'Pizza Rolls',
                        price: 5.5,
                        per: 'each',
                        details: '',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'Mini Pizzas',
                        price: 5.5,
                        per: 'each',
                        options: [
                            {
                                name: 'Margarita',
                                price: 5.5
                            },
                            {
                                name: 'Salami',
                                price: 5.5
                            },
                            {
                                name: 'Vegetarian',
                                price: 5.5
                            },
                            {
                                name: 'Hawaiian',
                                price: 5.5
                            },
                            {
                                name: 'Meat lovers',
                                price: 5.5
                            }
                        ],
                        details: '',
                        fillings: []
                    },
                    {
                        name: 'Bruschetta',
                        price: 12,
                        per: 'per serve',
                        options: [
                            {
                                name: 'Buffalo mozzarella & cherry tomatoes',
                                price: 12
                            },
                            {
                                name: 'Cherry tomatoes & basil (dairy free)',
                                price: 12
                            },
                            {
                                name: 'Sautéed mushrooms, olive pate & rocket garnish',
                                price: 12
                            }
                        ],
                        details: '',
                        fillings: []
                    }
                ]
            },
            {
                sectionName: 'Sweet',
                products: [
                    {
                        name: 'Mini Almond Croissant',
                        price: 5.5,
                        per: 'each',
                        details: '',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'Homemade Portuguese Tart',
                        price: 5.5,
                        per: 'each',
                        details: '',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'Mini Escargot',
                        price: 5.5,
                        per: 'each',
                        details: '',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'Mini Chocolate Croissant',
                        price: 5.5,
                        per: 'each',
                        details: '',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'Mixed Mini Danish',
                        price: 5.5,
                        per: 'each',
                        details: '',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'Mixed Mini Muffins',
                        price: 5.5,
                        per: 'each',
                        options: [
                            {
                                name: 'Lemon',
                                price: 5.5
                            },
                            {
                                name: 'Vegan',
                                price: 5.5
                            },
                            {
                                name: 'Cherry',
                                price: 5.5
                            }
                        ],
                        details: '',
                        fillings: []
                    },
                    {
                        name: 'Almond Slice',
                        price: 3,
                        per: 'each',
                        details: '',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'GF Chocolate Lava Cake',
                        price: 6,
                        per: 'each',
                        details: '',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'Lemon Tart',
                        price: 3,
                        per: 'each',
                        details: '',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'Browny Bite',
                        price: 3,
                        per: 'each',
                        details: '',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'Selection of Homemade Biscuits',
                        price: 15,
                        per: 'serve of 10',
                        details: 'serve of 10',
                        options: [],
                        fillings: []
                    }
                ]
            },
            {
                sectionName: 'Salad',
                products: [
                    {
                        name: 'Salad Bowls',
                        price: 36.6,
                        per: 'size dependent',
                        options: [
                            {
                                name: 'Chicken',
                                price: 36.6
                            },
                            {
                                name: 'Tuna',
                                price: 36.6
                            },
                            {
                                name: 'Salmon',
                                price: 36.6
                            },
                            {
                                name: 'Garden',
                                price: 36.6
                            }
                        ],
                        details: '',
                        fillings: []
                    },
                    {
                        name: 'Cheese Platter',
                        price: 60.6,
                        details: '',
                        options: [
                            {
                                name: 'Serves 6p',
                                price: 60.6
                            },
                            {
                                name: 'Serves 12p',
                                price: 110.6
                            },
                            {
                                name: 'Serves 20p',
                                price: 200.6
                            }
                        ],
                        fillings: []
                    },
                    {
                        name: 'Seasonal Fruit Platter',
                        price: 54.6,
                        details: '',
                        options: [
                            {
                                name: 'Serves 6p',
                                price: 54.6
                            },
                            {
                                name: 'Serves 12p',
                                price: 80.6
                            },
                            {
                                name: 'Serves 20p',
                                price: 120.6
                            }
                        ],
                        fillings: []
                    },
                    {
                        name: 'Jugs of Freshly Squeezed Juice',
                        price: 28,
                        per: 'each',
                        options: [
                            {
                                name: 'Apple',
                                price: 28
                            },
                            {
                                name: 'Orange',
                                price: 28
                            },
                            {
                                name: 'Pear',
                                price: 28
                            },
                            {
                                name: 'Watermelon',
                                price: 28
                            }
                        ],
                        details: '',
                        fillings: []
                    },
                    {
                        name: 'Smoothie Jugs',
                        price: 35,
                        per: 'each',
                        options: [
                            {
                                name: 'Banana',
                                price: 35
                            },
                            {
                                name: 'Mango',
                                price: 35
                            },
                            {
                                name: 'Mixed Berry',
                                price: 35
                            }
                        ],
                        details: '',
                        fillings: []
                    }
                ]
            },
            {
                sectionName: 'Packs',
                products: [
                    {
                        name: 'Aussie',
                        details: 'Selection of mini pies, mini quiches & mini sausage rolls. Serves 10p',
                        price: 90,
                        per: 'per serve',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'Asian Fusion',
                        details: 'Selection of mini dim sims, cocktail vegetable spring rolls, Thai & Indian spiced rice balls. Serves 10p',
                        price: 90,
                        per: 'per serve',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'Italian',
                        details: 'Selection of mini pizza, arancini & mini bruschetta. Serves 10p',
                        price: 90,
                        per: 'per serve',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'Sushi platter',
                        details: 'Mixed options. Serves 10p',
                        price: 90,
                        per: 'per serve',
                        options: [],
                        fillings: []
                    },
                    {
                        name: 'Rice paper roll platter',
                        details: 'Mixed options. Serves 10p',
                        price: 90,
                        per: 'per serve',
                        options: [],
                        fillings: []
                    }
                ]
            }
        ]
    },
    authoredMenuSchema: {
        type: 'object',
        required: [
            'clientId',
            'storeName',
            'transactionDescription',
            'currency',
            'hoursNotice',
            'timeZone',
            'locationExample',
            'sections'
        ],
        properties: {
            clientId: {
                type: 'string'
            },
            storeName: {
                type: 'string'
            },
            transactionDescription: {
                type: 'string'
            },
            currency: {
                type: 'string'
            },
            hoursNotice: {
                type: 'number'
            },
            timeZone: {
                type: 'string'
            },
            locationExample: {
                type: 'string'
            },
            sections: {
                type: 'array',
                items: {
                    type: 'object',
                    required: [
                        'sectionName',
                        'products'
                    ],
                    properties: {
                        sectionName: {
                            type: 'string'
                        },
                        fillings: {
                            type: 'array',
                            items: {
                                type: 'string'
                            }
                        },
                        products: {
                            type: 'array',
                            items: {
                                type: 'object',
                                required: [
                                    'name',
                                    'price'
                                ],
                                properties: {
                                    name: {
                                        type: 'string'
                                    },
                                    price: {
                                        type: 'number'
                                    },
                                    per: {
                                        type: 'string'
                                    },
                                    details: {
                                        type: 'string'
                                    },
                                    options: {
                                        type: 'array',
                                        items: {
                                            type: 'string'
                                        }
                                    },
                                    pricedOptions: {
                                        type: 'array',
                                        items: {
                                            type: 'object',
                                            required: [
                                                'name',
                                                'price'
                                            ],
                                            properties: {
                                                name: {
                                                    type: 'string'
                                                },
                                                price: {
                                                    type: 'number'
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    authoredMenu: {
        clientId: 'caffe-stazione',
        storeName: 'Caffe Stazione',
        transactionDescription: 'Catering services',
        currency: 'AUD',
        minOrder: 50,
        hoursNotice: 48,
        timeZone: 'Australia/Melbourne',
        locationExample: 'Realm meeting room 3',
        paypalEnabled: true,
        syntheticState: {
            order: {
                items: {},
                booking: {
                    name: 'John Doe',
                    phone: '0365554036',
                    email: 'sb-peeh526984152@business.example.com',
                    datetime: '2030-01-01T12:00',
                    location: 'TEST ONLY',
                    comment: 'TEST ONLY'
                }
            }
        },
        sections: [
            {
                sectionName: 'Savoury',
                fillings: [
                    'Ham, cheese & tomato',
                    'Chicken, mayo, & lettuce',
                    'Egg, cheese & mayo',
                    'Salami, cheese & rocket',
                    'Turkey, cranberry, swiss & cos',
                    'Olive pateortolana - vegan cheese, eggplant, spinach, basil, capsicum'
                ],
                products: [
                    {
                        name: 'Mini Egg & Bacon Brioche Rolls',
                        price: 5.3,
                        per: 'each'
                    },
                    {
                        name: 'Mini Bagels',
                        price: 5.5,
                        per: 'each',
                        options: [
                            'Smoked salmon & cream cheese',
                            'Smoked turkey & cranberry sauce'
                        ]
                    },
                    {
                        name: 'Mini Croissants',
                        price: 5.5,
                        per: 'each'
                    },
                    {
                        name: 'Baguettes',
                        details: 'cut in quarters',
                        price: 12.4,
                        per: 'each',
                        hasFillings: true
                    },
                    {
                        name: 'Wraps',
                        details: 'cut in thirds',
                        price: 12.4,
                        per: 'each',
                        hasFillings: true,
                        options: [
                            'Standard',
                            'Gluten free'
                        ]
                    },
                    {
                        name: 'Sandwiches',
                        details: 'cut in quarters',
                        price: 12.9,
                        per: 'each',
                        hasFillings: true,
                        options: [
                            'White',
                            'Wholemeal',
                            'Multigrain'
                        ],
                        pricedOptions: [
                            {
                                name: 'Gluten free',
                                price: 13.4
                            }
                        ]
                    },
                    {
                        name: 'Mini Rolls',
                        price: 5.5,
                        per: 'each',
                        hasFillings: true,
                        options: [
                            'White',
                            'Wholemeal',
                            'Multigrain'
                        ]
                    },
                    {
                        name: 'Mini Beef or Chicken Dim Sims',
                        price: 12,
                        per: 'serve of 10',
                        details: 'serve of 10'
                    },
                    {
                        name: 'Cocktail Vegetable Spring Rolls',
                        price: 12,
                        per: 'serve of 10',
                        details: 'serve of 10'
                    },
                    {
                        name: 'Mini Chicken Kiev Balls',
                        price: 15,
                        per: 'serve of 10',
                        details: 'serve of 10'
                    },
                    {
                        name: 'Boneless Crumbed Chicken Wings',
                        price: 12,
                        per: 'serve of 6',
                        details: 'serve of 6'
                    },
                    {
                        name: 'Spiced Rice Bites',
                        price: 12,
                        per: 'serve of 10',
                        options: [
                            'Thai',
                            'Indian'
                        ],
                        details: 'serve of 10'
                    },
                    {
                        name: 'Beef Mini Pastry',
                        price: 15,
                        per: 'serve of 6',
                        details: 'serve of 6'
                    },
                    {
                        name: 'Spinach & Feta Mini Pastry',
                        price: 15,
                        per: 'serve of 6',
                        details: 'serve of 6'
                    },
                    {
                        name: 'Mixed Arancini Balls',
                        price: 15,
                        per: 'serve of 10',
                        options: [
                            'Tomato & basil',
                            'Pumpkin & feta',
                            'Mushroom & white wine',
                            'Bacon & cheese'
                        ],
                        details: 'serve of 10'
                    }
                ]
            },
            {
                sectionName: 'Gluten Free Options',
                products: [
                    {
                        name: 'GF Spinach & Ricotta Rolls',
                        price: 5.5,
                        per: 'each'
                    },
                    {
                        name: 'GF Mini Sausage Rolls',
                        price: 5.5,
                        per: 'each'
                    },
                    {
                        name: 'GF Pumpkin Arancini',
                        price: 5.5,
                        per: 'each'
                    }
                ]
            },
            {
                sectionName: 'Pizza',
                products: [
                    {
                        name: 'Pizza Tray',
                        price: 50,
                        per: 'serve of 24 pieces',
                        options: [
                            'Margarita',
                            'Salami',
                            'Mushroom',
                            'Eggplant & pumpkin'
                        ],
                        details: 'serve of 24 pieces'
                    },
                    {
                        name: 'Pizza Rolls',
                        price: 5.5,
                        per: 'each'
                    },
                    {
                        name: 'Mini Pizzas',
                        price: 5.5,
                        per: 'each',
                        options: [
                            'Margarita',
                            'Salami',
                            'Vegetarian',
                            'Hawaiian',
                            'Meat lovers'
                        ]
                    },
                    {
                        name: 'Bruschetta',
                        price: 12,
                        per: 'per serve',
                        options: [
                            'Buffalo mozzarella & cherry tomatoes',
                            'Cherry tomatoes & basil (dairy free)',
                            'Sautéed mushrooms, olive pate & rocket garnish'
                        ]
                    }
                ]
            },
            {
                sectionName: 'Sweet',
                products: [
                    {
                        name: 'Mini Almond Croissant',
                        price: 5.5,
                        per: 'each'
                    },
                    {
                        name: 'Homemade Portuguese Tart',
                        price: 5.5,
                        per: 'each'
                    },
                    {
                        name: 'Mini Escargot',
                        price: 5.5,
                        per: 'each'
                    },
                    {
                        name: 'Mini Chocolate Croissant',
                        price: 5.5,
                        per: 'each'
                    },
                    {
                        name: 'Mixed Mini Danish',
                        price: 5.5,
                        per: 'each'
                    },
                    {
                        name: 'Mixed Mini Muffins',
                        price: 5.5,
                        per: 'each',
                        options: [
                            'Lemon',
                            'Vegan',
                            'Cherry'
                        ]
                    },
                    {
                        name: 'Almond Slice',
                        price: 3,
                        per: 'each'
                    },
                    {
                        name: 'GF Chocolate Lava Cake',
                        price: 6,
                        per: 'each'
                    },
                    {
                        name: 'Lemon Tart',
                        price: 3,
                        per: 'each'
                    },
                    {
                        name: 'Browny Bite',
                        price: 3,
                        per: 'each'
                    },
                    {
                        name: 'Selection of Homemade Biscuits',
                        price: 15,
                        per: 'serve of 10',
                        details: 'serve of 10'
                    }
                ]
            },
            {
                sectionName: 'Salad',
                products: [
                    {
                        name: 'Salad Bowls',
                        price: 36.6,
                        per: 'size dependent',
                        options: [
                            'Chicken',
                            'Tuna',
                            'Salmon',
                            'Garden'
                        ]
                    },
                    {
                        name: 'Cheese Platter',
                        price: 60.6,
                        pricedOptions: [
                            {
                                name: 'Serves 6p',
                                price: 60.6
                            },
                            {
                                name: 'Serves 12p',
                                price: 110.6
                            },
                            {
                                name: 'Serves 20p',
                                price: 200.6
                            }
                        ]
                    },
                    {
                        name: 'Seasonal Fruit Platter',
                        price: 54.6,
                        pricedOptions: [
                            {
                                name: 'Serves 6p',
                                price: 54.6
                            },
                            {
                                name: 'Serves 12p',
                                price: 80.6
                            },
                            {
                                name: 'Serves 20p',
                                price: 120.6
                            }
                        ]
                    },
                    {
                        name: 'Jugs of Freshly Squeezed Juice',
                        price: 28,
                        per: 'each',
                        options: [
                            'Apple',
                            'Orange',
                            'Pear',
                            'Watermelon'
                        ]
                    },
                    {
                        name: 'Smoothie Jugs',
                        price: 35,
                        per: 'each',
                        options: [
                            'Banana',
                            'Mango',
                            'Mixed Berry'
                        ]
                    }
                ]
            },
            {
                sectionName: 'Packs',
                products: [
                    {
                        name: 'Aussie',
                        details: 'Selection of mini pies, mini quiches & mini sausage rolls. Serves 10p',
                        price: 90,
                        per: 'per serve'
                    },
                    {
                        name: 'Asian Fusion',
                        details: 'Selection of mini dim sims, cocktail vegetable spring rolls, Thai & Indian spiced rice balls. Serves 10p',
                        price: 90,
                        per: 'per serve'
                    },
                    {
                        name: 'Italian',
                        details: 'Selection of mini pizza, arancini & mini bruschetta. Serves 10p',
                        price: 90,
                        per: 'per serve'
                    },
                    {
                        name: 'Sushi platter',
                        details: 'Mixed options. Serves 10p',
                        price: 90,
                        per: 'per serve'
                    },
                    {
                        name: 'Rice paper roll platter',
                        details: 'Mixed options. Serves 10p',
                        price: 90,
                        per: 'per serve'
                    }
                ]
            }
        ]
    },
    cssClasses: {},
    missingCssClassError: true,
    headerElementName: 'stazione-header'
};
