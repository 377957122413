export default ({ lib, ui, constants }) => ({ order, onRemove, deliveryVisible = true }) => {

    const deliveryNote = order.booking ? lib.deliveryNote(order.booking).split('\n').map((line, i) => <div key={i}>{line}</div>) : null;

    const items = Object.values(order.items).map((item, i) => {
        const handleRemove = () => onRemove(item);
        const text = `${item.quantity} x ${item.description}`;
        return <div key={i} className={ui.cssClass('item-summary')}>
            <div className={ui.cssClass('item-description')}>{text}</div>
            {onRemove && <div><button onClick={handleRemove}>Remove</button></div>}
        </div>;
    });

    return <div className={ui.cssClasses('your-order', ['readonly', !onRemove])}>
        {deliveryVisible && deliveryNote}
        <div className={ui.cssClass('items')}>{items}</div>
        <p>Order total: ${order.total} {constants.menu.currency}</p>
    </div >;

};
