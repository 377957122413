export default ({ ui }) => ({ order, onCheckout }) => {

    if (order.empty) return null;

    const linkText = `Checkout ${order.count} item${order.count === 1 ? '' : 's'} at $${order.total}`;

    return <div className={ui.cssClass('sticky')}>
        <a onClick={onCheckout}>{linkText}</a>
    </div>;

};
