import collapse from './collapse.mjs';
import cssClass from './css-class.mjs';
import cssClasses from './css-classes.mjs';
import expand from './expand.mjs';
import scrollToTop from './scroll-to-top.mjs';

export default {
    collapse,
    cssClass,
    cssClasses,
    expand,
    scrollToTop
};
