export default ({ react, reactRouter, pureComponents, effects, ui }) => ({ order }) => {

    const { Link } = reactRouter;

    react.useEffect(() => {
        effects.toggleProduct({ selectedProduct: null });
    }, []);

    const handleBooking = async (booking, terms) => {
        const order = effects.updateOrder({ booking, terms });
        if (terms === 'payLater') await effects.captureInstorePayment(order);
    };

    const bookingForm = <pureComponents.BookingForm order={order} onSubmit={handleBooking} calculateMinBookingDate={effects.calculateMinBookingDate} />;

    return <div>
        <pureComponents.Notices hoursNoticeVisible={false} minOrderVisible={!order.minReached} />
        <div className={ui.cssClass('sticky')}><Link to="/">Continue ordering</Link></div>
        {order.minReached && bookingForm}
        <h2>Your order</h2>
        <pureComponents.Order order={order} deliveryVisible={false} onRemove={effects.removeItem} />
    </div>;

};
