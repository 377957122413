import Order from './1--Order.mjs';
import Checkout from './2--Checkout.mjs';
import Payment from './3--Payment.mjs';
import Completed from './4--Completed.mjs';
import MenuEditor from './MenuEditor.mjs';
import Router from './Router.mjs';

export default {
    Order,
    Checkout,
    Payment,
    Completed,
    MenuEditor,
    Router
};
