import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

export default ({ effects, ui, constants }) => () => {

    const options = {
        'client-id': constants.paypal.client,
        'currency': constants.menu.currency
    };

    return <div className={ui.cssClass('paypal')}>
        <PayPalScriptProvider options={options}>
            <PayPalButtons
                createOrder={async () => {
                    const order = effects.getOrder();
                    const request = () => effects.captureOrder({ ...order, paymentMethod: 'paypal' });
                    const errorMessage = 'We\'re sorry, something went wrong while setting up your payment';
                    const { payment } = await effects.makeRequest(request, errorMessage);
                    return payment.token;
                }}
                onApprove={async ({ paymentID: paymentId, payerID: payerId }) => {
                    const { orderId } = effects.getOrder();
                    const request = () => effects.capturePayment({ orderId, paymentId, payerId });
                    const errorMessage = 'We\'re sorry, something went wrong while processing your payment';
                    return effects.makeRequest(request, errorMessage);
                }}
            />
        </PayPalScriptProvider>
    </div>;
};
