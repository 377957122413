export default () => element => {

    const listener = () => {
        element.removeEventListener('transitionend', listener);
        element.style.height = 'auto';
    };

    element.style.height = element.scrollHeight + 'px';
    element.addEventListener('transitionend', listener);

};
