import { useState, useRef, useEffect } from 'react';
import compose from './compose.mjs';

export default ({ window, cssClasses, config, onComposed }) => {

    const [composition, setComposition] = useState();
    const [state, setState] = useState();
    const stateRef = useRef();
    stateRef.current = state;

    useEffect(() => {
        const get = () => stateRef.current;
        const set = changes => setState(state => ({ ...state, ...changes }));
        const store = { get, set };
        const compositionName = 'Catering Website';
        const composition = compose({ compositionName, window, store, cssClasses, config });
        if (!window.apps) window.apps = [];
        window.apps.push({ cateringWebsite: composition });
        const { constants } = composition;
        setComposition(composition);
        setState(constants.initialState);
    }, []);

    return composition && onComposed(composition);

};
