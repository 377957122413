export default ({ constants }) => order => {

    const menuId = constants.menu.id;
    const { booking, paymentMethod } = order;

    const items = Object.values(order.items).map(item => {
        const { name, option, filling, quantity } = item;
        return { name, option, filling, quantity };
    });

    return { menuId, items, booking, paymentMethod };

};
