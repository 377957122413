// import { Form } from '@rjsf/bootstrap-4';

export default () => () => {

    // return <div>
    //     <Form
    //         schema={constants.authoredMenuSchema}
    //         formData={constants.authoredMenu}
    //     />
    // </div>;

};

// ● Test suite failed to run

// Must use import to load ES Module: /Users/mattriley/Home/Code/catering-website/node_modules/nanoid/index.browser.js

//   at Runtime.requireModule (node_modules/jest-runtime/build/index.js:1011:21)
//   at Object.<anonymous> (node_modules/@rjsf/core/dist/core.cjs.development.js:21:14)
//   at Object.<anonymous> (node_modules/@rjsf/core/dist/index.js:7:20)
