export default ({ lib, constants }) => (item, order) => {

    const describeItem = () => {
        const { name, option, filling } = item;
        return lib.describeItem({ name, option, filling });
    };

    const product = constants.productMap[item.name];
    const option = product.options.find(o => o.name === item.option);
    const price = [option?.price, product.price].find(p => !!p);
    const description = describeItem();
    const quantity = (order.items[description]?.quantity || 0) + item.quantity;
    const totalPrice = price * quantity;
    return { ...item, description, quantity, price, totalPrice };

};
