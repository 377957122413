export default ({ lib, constants }) => order => {

    const { items } = order;
    const total = lib.calculateTotalCost(items);
    const minReached = total >= constants.menu.minOrder;
    const count = Object.values(items).reduce((sum, item) => sum + item.quantity, 0);
    const empty = count === 0;
    const completed = order.payment?.status === 'completed';
    return { ...order, total, minReached, count, empty, completed };

};
