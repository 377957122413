export default ({ constants }) => booking => {

    const dateFormat = {
        year: 'numeric', month: 'short', day: 'numeric',
        hour: 'numeric', minute: 'numeric',
        hour12: true,
        timeZone: constants.menu.timeZone
    };

    const time = new Date(booking.datetime).toLocaleString('en-AU', dateFormat);
    return `Delivery to ${booking.location} on ${time}.\nContact ${booking.name} on ${booking.phone} or ${booking.email}.`;

};
