export default ({ constants }) => (...parts) => {

    const target = parts.join('-');

    const result = constants.cssClasses[target];

    if (!result && constants.missingCssClassError) {
        throw new Error(`CSS class "${target}" not found`);
    }

    return result ?? target;

};
