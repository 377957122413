export default ({ ui }) => ({ lastItemAdded, onAddAnother }) => {

    return <div>
        <p>{lastItemAdded}</p>
        <div className={ui.cssClass('horizontal-spacing')}>
            <span>Item added!</span>
            <button onClick={onAddAnother}>Add another</button>
        </div>
    </div>;

};
