export default ({ effects, store }) => async (callback, errorMessage) => {

    try {
        store.set({ status: 'loading' });
        const result = await callback();
        effects.updateOrder(result);
        store.set({ status: null });
        return result;
    } catch (error) {
        store.set({ status: 'error', error, errorMessage });
    }

};
