export default () => ({ product, fillingIndex, onFillingChange }) => {

    if (!product.fillings.length) return null;

    const fillings = product.fillings.map((f, i) => {
        const radio = <input type="radio"
            name={`${product.name}-fillings`}
            value={i}
            checked={i === fillingIndex}
            onChange={() => onFillingChange(i)}
        />;
        return <label key={i}>{radio}{f.name}</label>;
    });

    return <div>
        <h3>Fillings</h3>
        {fillings}
    </div>;

};
