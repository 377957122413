export default ({ reactRouter, components, pureComponents, effects, ui }) => ({ order }) => {

    const { Link } = reactRouter;

    const handleBack = () => effects.updateOrder({ terms: null });

    const paypal = <div>
        <components.PayPal />
        <h2>Your order</h2>
        <pureComponents.Order order={order} />
    </div>;

    return <div>
        <div className={ui.cssClass('sticky')}><Link onClick={handleBack} to='/checkout'>Back to checkout</Link></div>
        {order.terms === 'payNow' && paypal}
    </div>;

};
