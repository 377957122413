html, body {
    margin: 0;
    font-size: 18px;
}

html, body, :global(#app), :global(#container) {
    height: 100%;
    min-height: 100%;
}

:global(#container) {
    position: relative;
    height: auto !important;
}

:global(#header) {
    text-align: center;
}

:global(#content) {
    width: 600px;
    margin: 0 auto; /* centered */
    padding-bottom: 3rem; /* allow room for footer */
    text-align: center;
}

:global(#footer) {
    position: absolute;
    width: 100%;
    bottom: 0; /* stick to bottom */
}

body, input, button {
    font-family: 'Roboto Condensed', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h2 {
    font-size: 1.5rem;
    text-align: center;
}

h3 {
    font-size: 1rem;    
    text-transform: uppercase;
    border-bottom: solid 1px black;
    text-align: left;;
}

input {
    border: solid 1px black;
}

input, button {
    color: black;
    font-size: 1rem;
    padding: 0.7rem 1rem;
    margin: 0.3rem 0;
}

button {    
    background-color: #f5f5f5;
    border: 1px solid transparent;
    cursor: pointer;
}

button:hover, .product-header:hover {    
    transition: background-color 0.5s ease;
    color: white;
    /* background-color: rgb(26 35 90) !important; */
    background-color: rgb(28, 52, 144) !important;
    cursor: pointer;
}

label {
    display: flex;    
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
}

input[type=radio] {
    flex-shrink: 0;
    height: 1rem;
    width: 1rem;
    margin: 0.3rem 1rem;
    margin-left: 0;
}

a, a:visited {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.collapsable {
    overflow: hidden;
    transition: height 0.3s ease;
}

.visible-true {
    display: auto;
}

.visible-false {
    display: none;
}

.sticky {
    position: sticky;
    top: 0; 
    text-align: center;    
    font-weight: bold;
    font-size: 1rem;
    background-color: white;
    padding: 1rem;
}

.horizontal-spacing > * {
    margin: 0 0.5rem;
}

.notices {
    font-weight: bold;
}

.notices > span::after {
    content: " ";
}

.menu {
    text-align: left;
    padding-bottom: 2rem;      
}

.product {
    margin-bottom: 1rem;
}

.product.selected-true {
    border-bottom: solid 2px rgb(28, 52, 144);
    /* #1a235a */
}

.product.selected-false .collapsable {
    height: 0;
}

.product .product-header {
    display: flex;
    justify-content: space-between;
    background-color: rgb(245, 245, 245);
    cursor: pointer;
    padding: 1rem 1.5rem;
}

.product.selected-true .product-header {
    background-color:  rgb(28, 52, 144);
    color: white;
}

.product .product-header .details {
    color: gray;
    font-size: smaller;
    margin-left: 10px;
    display: inline-block;
}

.product .product-header:hover .details {
    color: white !important;
}

.product.selected-true .product-header .details {
    color: white;
}

.product-content {
    text-align: center;
    height: 0px;
    margin: 0 1rem;
    margin-bottom: 1rem;
}

.product-content .customisations > div {
    text-align: left;
    margin: 1.5rem 0;
}

.product-content .customisations label {
    display: flex;
    align-items: center;
}

.option-price {
    margin-left: 1rem;
}

.quantity-bar {
    text-align: center;
    margin-top: 1rem;
}

.quantity {
    display: inline-block;
    width: 2rem;
}

form.booking {
    text-align: left;
    padding: 1rem;
    width: 100%;
    min-width: 100%; 
    box-sizing: border-box;
}

form.booking > div {
    padding-bottom: 1rem;
    position: relative;
}

form.booking label {
    font-weight: bold;
    padding-bottom: 0.2rem;
}

form.booking label::after {
    content: ":";
}

form.booking input {
    width: 100%;
    min-width: 100%; 
    box-sizing: border-box;
}

form.booking .example {
    text-align: right;
    font-size: 0.8rem;
    position: absolute;
    width: 100%;
}

form button {
    width: 100%;
}

.your-order {
    margin: 1rem;
}

.your-order .items {
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    padding: 1rem 0;
    margin: 2rem 0;
}

.your-order .item-summary {
    padding: 0.2rem 0;
}

.your-order.readonly-true {
    text-align: center;
}

.your-order.readonly-false .item-summary {
    display: flex;
    justify-content: space-between;
    text-align: left;
}

.your-order .item-description {
    padding: 1rem 0;
}

.paypal {
    padding: 1rem;
}

.error-message {
    font-weight: bold;
    background-color: pink;
    padding: 1rem;
}

.thankyou {
    font-size: 2rem;
}
  
@media screen and (max-device-width: 600px) {

    :global(#content) {
        width: auto;
    }

    :global(#footer) {
        font-size: 0.8rem;
    }

}
