import react from 'react';
import * as reactRouter from 'react-router-dom';
import buildProductMap from './build-product-map.mjs';
import defaultConfig from './default-config.mjs';
import modules from './modules/index.mjs';
import composer from 'module-composer';
import 'module-composer/extensions/mermaid.js';
import 'module-composer/extensions/global-register.js';

export default ({ compositionName, window, store, cssClasses, config }) => {

    const productMap = buildProductMap(defaultConfig.menu);
    const { configure } = composer(modules);
    const initialState = config.isTest ? config.syntheticState : config.initialState;
    const { compose, constants } = configure(defaultConfig, { compositionName, productMap, cssClasses, initialState }, config);

    const { ui } = compose('ui', { constants, window });
    const { io } = compose('io', { constants, window });
    const { lib } = compose('lib', { constants });
    const { effects } = compose('effects', { store, lib, io, constants });
    const { pureComponents } = compose('pureComponents', { react, lib, ui, io, constants });
    const { components } = compose('components', { react, reactRouter, pureComponents, effects, store, io, ui, lib, constants });
    compose('views', { react, reactRouter, components, pureComponents, effects, lib, ui, constants });
    return compose.end();

};
