export default ({ ui }) => ({ status, errorMessage, onDismiss }) => {

    if (status === 'loading') {
        return <img src="loading.gif" alt="Loading" />;
    }

    if (status === 'error') {
        return <div className={ui.cssClasses('error-message', 'horizontal-spacing')}>
            <span>{errorMessage}</span>
            <a onClick={onDismiss}>Dismiss</a>
        </div>;
    }

    return null;

};
