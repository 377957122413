/* eslint-disable no-undef */
import * as cssClasses from './app.module.css';
import { createRoot } from 'react-dom/client';
import React from 'react';
import Launch from './Launch.mjs';

const script = document.createElement('script');
script.src = process.env.INDEX_HTML_WEB_COMPONENTS;
document.head.append(script);

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
const isSynthetic = params.synth === '1';

const onComposed = ({ modules }) => <modules.views.Router />;
const config = { isSynthetic };

const launch = <Launch
    window={window}
    cssClasses={cssClasses}
    config={config}
    onComposed={onComposed}
/>;

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<React.StrictMode>{launch}</React.StrictMode>);
