export default ({ react, reactRouter, components, pureComponents, store, io, ui, constants }) => ({ children }) => {

    const location = reactRouter.useLocation();

    react.useEffect(ui.scrollToTop, [location]);

    react.useEffect(() => {
        io.mixpanel && io.mixpanel.track('pageview', { path: location.pathname });
    }, [location]);

    const reroute = components.Reroute();
    if (reroute) return reroute;

    const { status, errorMessage } = store.get();
    const handleDismiss = () => store.set({ status: null });

    const header = document.createElement(constants.headerElementName);
    const headerHtml = { __html: header.outerHTML };

    return <div id="container">
        <div id="header" dangerouslySetInnerHTML={headerHtml}>
        </div>
        <div id="content">
            <pureComponents.Status status={status} errorMessage={errorMessage} onDismiss={handleDismiss} />
            {children}
        </div>
        <div id="footer">
            <dev-bar app-name={constants.appName}></dev-bar>
        </div>
    </div>;

};
