export default ({ reactRouter, views, components, effects }) => () => {

    const { HashRouter, Routes, Route, Navigate } = reactRouter;

    const order = effects.getOrder();

    return <HashRouter>
        <components.App>
            <Routes>
                <Route path="/" element={<views.Order order={order} />} />
                <Route path="/checkout" element={<views.Checkout order={order} />} />
                <Route path="/payment" element={<views.Payment order={order} />} />
                <Route path="/completed" element={<views.Completed order={order} />} />
                <Route path="/edit" element={<views.MenuEditor />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </components.App>
    </HashRouter>;

};
