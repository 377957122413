export default ({ lib, ui }) => ({ product, optionIndex, onOptionChange }) => {

    if (!product.options.length) return null;

    const options = product.options.map((option, i) => {
        const displayPrice = lib.optionDisplayPrice(option, product);

        const radio = <input type="radio"
            name={`${product.name}-options`}
            value={i}
            checked={i === optionIndex}
            onChange={() => onOptionChange(i)}
        />;

        return <label key={i}>
            {radio}
            <span>{option.name}</span>
            <span className={ui.cssClass('option-price')}>{displayPrice}</span>
        </label>;
    });

    return <div>
        <h3>Options</h3>
        {options}
    </div >;

};
