import mixpanel from 'mixpanel-browser';

export default ({ io, constants }) => () => {

    constants.mixpanelToken && mixpanel.init(constants.mixpanelToken, { debug: constants.isTest });
    const maybeMixpanel = constants.mixpanelToken ? { mixpanel } : {};

    return {
        ...io,
        ...maybeMixpanel,
        now: Date.now,
        fetch: (...args) => fetch(...args)
    };

};
