export default ({ lib, ui }) => ({ product, onSelect }) => {

    const prices = lib.sortPrices(product);
    const price = prices.length > 1 ? `from ${prices[0]}` : prices[0];

    return <div className={ui.cssClass('product-header')} onClick={() => onSelect(product)}>
        <div>
            <span>{product.name}</span>
            <span className={ui.cssClass('details')}>{product.details}</span>
        </div>
        <div>{price}</div>
    </div >;

};
